<template>
  <FullScreenLayout class="bg-gray-200">
    <LoginFragment />
  </FullScreenLayout>
</template>

<script>
import FullScreenLayout from "@/modules/core/layouts/FullScreenLayout";
import LoginFragment from "@/modules/auth/fragments/LoginFragment";

export default {
  name: "Login",
  components: {
    FullScreenLayout,
    LoginFragment
  }
};
</script>
