<template>
  <form onsubmit="return false">
    <slot />
  </form>
</template>

<script>
export default {
  name: "RForm"
};
</script>
