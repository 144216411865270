<template>
  <div role="progressbar" :class="classList"></div>
</template>

<script>
export default {
  name: "RLoader",
  props: {
    size: {
      type: String,
      required: false,
      default: "2"
    }
  },
  setup(props) {
    const classList = `inline-flex px-${props.size} py-${props.size} circle-loader`;

    return { classList };
  }
};
</script>

<style scoped>
.circle-loader {
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.5);
  border-left: 3px solid rgba(255, 255, 255, 1);
  animation: animation-rotate 1s cubic-bezier(0.41, 0.25, 0.32, 0.83) infinite;
}

@keyframes animation-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
</style>
