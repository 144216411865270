<template>
  <FullScreenLayout>
    <SuspenseLoadingIndicator
      message="Please visit this website using the link you received!"
    />
  </FullScreenLayout>
</template>

<script>
import FullScreenLayout from "@/modules/core/layouts/FullScreenLayout";
import SuspenseLoadingIndicator from "@/modules/core/components/SuspenseLoadingIndicator";

export default {
  name: "Home",
  components: {
    FullScreenLayout,
    SuspenseLoadingIndicator
  }
};
</script>
