<template>
  <button :type="type" :class="classList" :disabled="disabled || loading">
    <slot name="loader" v-if="loading">
      <RLoader />
    </slot>
    <slot v-else />
  </button>
</template>

<script>
import { computed } from "vue";
import RLoader from "@/modules/core/components/RLoader";

export default {
  name: "RButton",
  components: {
    RLoader
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: "primary"
    },
    size: {
      type: String,
      required: false,
      default: "md"
    },
    type: {
      type: String,
      required: false,
      default: "button"
    }
  },
  setup(props) {
    const classList = computed(() => {
      return [
        "btn",
        `h-${heightFromSize()}`,
        props.color ? `btn-${props.color}` : "",
        props.loading ? "btn-loading" : null,
        props.disabled ? "btn-disabled" : null
      ]
        .filter(c => c)
        .join(" ");
    });

    const heightFromSize = () => {
      switch (props.size) {
        case "xs":
          return 8;
        case "sm":
          return 10;
        case "md":
          return 12;
        case "lg":
          return 14;
        case "xl":
          return 16;
      }
    };

    return { classList };
  }
};
</script>

<style scoped>
.btn {
  @apply flex justify-center items-center px-4 relative rounded overflow-hidden cursor-pointer focus:outline-none;
}

.btn:before {
  @apply absolute inset-0 z-10 opacity-0;
  will-change: background-color, opacity;
  content: " ";
}

.btn:hover:before,
.btn:focus:before {
  @apply bg-current opacity-10;
}

.btn-disabled:before,
.btn-loading:before,
.btn-disabled:hover:before,
.btn-loading:hover:before {
  cursor: default;
  @apply bg-current opacity-20;
}

.btn-primary {
  @apply bg-blue-500 text-white;
}

.btn-danger {
  @apply bg-red-500 text-white;
}

.btn-success {
  @apply bg-green-500 text-white;
}

.btn-neutral {
  @apply bg-gray-200 text-gray-500;
}
</style>
