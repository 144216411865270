<template>
  <div class="flex flex-col w-96 p-8 space-y-16 bg-white rounded-lg shadow-xl">
    <h1 class="font-bold text-3xl text-gray-800">Sign in to Relay</h1>
    <RForm class="space-y-4" @submit="signIn">
      <RTextfield
        type="text"
        label="Email"
        autocomplete="username"
        v-model="userCredentials.username"
        :disabled="isLoading"
        :rules="[required, email]"
      />
      <RTextfield
        type="password"
        label="Password"
        autocomplete="current-password"
        v-model="userCredentials.password"
        :disabled="isLoading"
        :rules="[required]"
      />
      <label
        v-if="error"
        class="block text-center text-red-500 text-lg font-bold"
      >
        Invalid ursername or password!
      </label>
      <div class="py-4">
        <RButton
          class="w-full"
          type="submit"
          :loading="isLoading"
          :disabled="!canSignIn"
        >
          <span>Sign in</span>
        </RButton>
      </div>
    </RForm>
  </div>
</template>

<script>
import { reactive, computed, watch } from "vue";
import { useRouter } from "vue-router";
import useAuth from "@/modules/auth/compositions/useAuth.js";
import useValidation from "@/modules/core/compositions/useValidation.js";
import RForm from "@/modules/core/components/RForm";
import RTextfield from "@/modules/core/components/RTextField";
import RButton from "@/modules/core/components/RButton";

export default {
  name: "LoginFragment",
  components: {
    RForm,
    RTextfield,
    RButton
  },
  setup() {
    const { isLoading, error, signInWithEmailAndPassword } = useAuth();
    const { required, email } = useValidation();
    const router = useRouter();
    const userCredentials = reactive({
      username: null,
      password: null
    });

    const canSignIn = computed(() => {
      return !!userCredentials.username && !!userCredentials.password;
    });

    watch(error, () => {
      if (error.value) {
        userCredentials.password = null;
      }
    });

    const signIn = async () => {
      await signInWithEmailAndPassword(userCredentials);
      if (!error.value) {
        router.push({ name: "Admin" });
      }
    };

    return {
      userCredentials,
      canSignIn,
      isLoading,
      error,
      signIn,
      required,
      email
    };
  }
};
</script>
