<template>
  <div class="w-100">
    <label v-if="label" :for="id" class="block mb-1 font-bold">
      {{ label }}
    </label>
    <input
      class="bg-gray-200 rounded px-2 py-3 w-full focus:outline-none"
      :id="id"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :autocomplete="autocomplete"
      @input="onInputChanged($event.target.value)"
      @blur="validate($event.target.value)"
    />
    <label v-if="validationError" class="text-red-500 text-xs">
      {{ validationError }}
    </label>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "RTextField",
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    modelValue: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    autocomplete: {
      type: String,
      required: false
    },
    rules: {
      type: Array,
      required: false,
      default: null
    }
  },
  setup(props, { emit }) {
    const validationError = ref(null);

    const onInputChanged = value => {
      emit("update:modelValue", value);
      validate(value);
    };

    const validate = value => {
      let errorMessage = null;
      props.rules?.forEach(ruleHandler => {
        let result = ruleHandler(value);
        if (result != true) {
          errorMessage = result;
          return;
        }
      });

      validationError.value = errorMessage;
    };

    return { onInputChanged, validate, validationError };
  }
};
</script>
